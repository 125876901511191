import React, { useState } from "react";
import "./App.css";

const getImages = async (query) => {
  const url = "https://serverless-api.idanmasas.workers.dev/";

  const resp = await fetch(url, {
    // Send a POST request
    method: "POST",
    // With a JSON-stringified body containing the query from our input
    body: JSON.stringify({ query }),
    // Set the `Content-type` header so our API knows that the request
    // is sending JSON
    headers: { "Content-type": "application/json" },
  });

  return resp.json();
};

function App() {
  const [query, setQuery] = useState("");
  const [images, setImages] = useState([]);

  const search = async () => {
    const results = await getImages(query);
    setImages(results);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      search();
    }
  };

  return (
    <div className="App">
      <div className="search-head-container">
        <input
          type="input"
          onKeyUp={(e) => handleKeyPress(e)}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Search query.."
        />
        <button onClick={() => search()}>Search</button>
      </div>
      <div className="images-container">
        {images.map((image) => (
          <a key={image.id} href={image.link} target="_blank">
            <img src={image.image} />
          </a>
        ))}
      </div>
    </div>
  );
}

export default App;
